import React from "react";
import "./text_desc.css";

function Text_desc(props) {
  return (
    <div className="col-12 col-md-6 d-flex flex-column justify-content-center p-0">
      <h3 className={`${props.backgroundTitre} text-dark fw-medium text-center p-3 rounded-4`}>
        {props.titre}
        <img src={props.icon} alt={`Icône ${props.titre}`} className="icon ms-3" />
      </h3>
      <div className="p-5 rounded-4">
        <p>{props.description}</p>
      </div>
    </div>
  );  
}

export default Text_desc;
