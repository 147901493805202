import React, { useEffect } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Home from "../../Components/Home/Home";
import Footer from "../../Components/Footer/Footer";

function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0); // Fait défiler la fenêtre en haut de la page
  }, []);

  return (
    <main className="d-flex flex-column align-items-center mb-3">
      <Navbar />
      <Home/>
      <Footer/>

    </main>
  );
}

export default HomePage;
