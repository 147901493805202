import React from "react";
import "./footer.css";
import logoSwitch from "../../Images/logoSwitch.png";
import facebook from "../../Images/facebook.png";
import insta from "../../Images/insta.png";
import logoX from "../../Images/X.png";
import swuok from "../../Images/swuok.png";
import logo13 from "../../Images/13.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="border-top mt-5 w-75">
      <article className="d-flex flex-column align-items-center flex-lg-row">
        <section className="w-75 mt-3 d-flex justify-content-center justify-content-lg-start">
          <a href="https://www.chezswitch.fr/#/" target="_blank" rel="noreferrer">
            <img
              src={logoSwitch}
              className="img-fluid icone"
              alt="logoSwitch"
            />
          </a>
        </section>
        <section className="w-75 mt-3 d-flex justify-content-center">
          <a href="https://www.facebook.com/chezswitch/" target="_blank" rel="noreferrer">
            <img
              src={facebook}
              className="img-fluid icone"
              alt="Facebook"
            />
          </a>
          <a href="https://www.instagram.com/chezswitch/?hl=fr" target="_blank" rel="noreferrer">
            <img
              src={insta}
              className="img-fluid icone"
              alt="Instagram"
            />
          </a>
          <a href="https://twitter.com/CHEZSWITCHfr" target="_blank" rel="noreferrer">
            <img
              src={logoX}
              className="img-fluid icone"
              alt="Logo X"
            />
          </a>
        </section>
        <section className="w-75 mt-3 d-flex justify-content-center justify-content-lg-end">
          <a href="https://swu-coin.com/" target="_blank" rel="noreferrer">
            <img
              src={swuok}
              alt="swuok"
              className="icone"
            />
          </a>
          <a href="https://www.d13.fr/" target="_blank" rel="noreferrer">
            <img
              src={logo13}
              alt="logo13"
              className="icone"
            />
          </a>
        </section>
      </article>
      <article className="w-100 d-flex justify-content-between justify-content-sm-center mt-5">
        <section className="me-sm-3">
          <Link
            to="/mentions-legales"
            className="fontSizeLink text-decoration-none text-dark"
          >
            MENTIONS LÉGALES
          </Link>
        </section>
        <section className="my-1 border border-dark"></section>
        <section className="ms-sm-3">
          <Link
            to="/politique-confidentialite"
            className="fontSizeLink text-decoration-none text-dark"
          >
            POLITIQUE DE CONFIDENTIALITÉ
          </Link>
        </section>
      </article>
      <article className="w-100 d-flex justify-content-center mt-5">
        <section>
          <p id="fontSizeCopyRight">
            © 2023 SWITCH - L'énergie est notre avenir, économisons-la !
          </p>
        </section>
      </article>
    </footer>
  );
}

export default Footer;
