import React, { useEffect} from 'react'
import Navbar from '../../Components/Navbar/Navbar';
import TitlePage from '../../Components/TitlePage/TitlePage';
import ArticleMentionsLegales from '../../Components/ArticleMentionsLegales/ArticleMentionsLegales';
import Footer from '../../Components/Footer/Footer';


function MentionsLegales() {
    useEffect(() => {
        window.scrollTo(0, 0); // Fait défiler la fenêtre en haut de la page
      }, []);
  return (
   <main  className="d-flex flex-column align-items-center mb-3">
    <Navbar/>
    <TitlePage title="MENTIONS LÉGALES" />
    <ArticleMentionsLegales/>
    <Footer/>
   </main>
  )
}

export default MentionsLegales;