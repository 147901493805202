import React from "react";
import main from "../../Images/main_energie.png";
import eolienne from "../../Images/Eolienne.jpg";
import barrage from "../../Images/Barrage.jpg";
import ContainerDesc from "../ContainerDesc/Container_desc";
import vert from "../../Images/icones_vert.png";
import panneau from "../../Images/icones_panneau.png";
import conv from "../../Images/icones_conv.png";
import Title from "../Titre/Titre";
import "./home.css";

function Home() {
  const containersData = [
    {
      inversion: "0",
      titre: "Simple, transparent et sans surprise",
      icon: vert,
      backgroundTitre: "fondG",
      description:
        "Nous comprenons que pour nos clients, l'énergie peut être un sujet compliqué et source de confusion. C'est pourquoi chez Switch Energy, nous nous engageons à simplifier les choses autant que possible. Nous souhaitons vous offrir une expérience claire et transparente, en vous aidant à comprendre exactement ce que vous payez et pourquoi, sans avoir à vous inquiéter des coûts cachés ou des surprises désagréables. Nous sommes là pour vous accompagner et répondre à toutes vos questions de manière honnête et transparente, afin que vous puissiez avoir l'esprit tranquille.",
      image: main,
      ltImg: "Illustration d'une main",
      class: "imgPosition",
    },
    {
      inversion: "1",
      titre: "Des offres vertes",
      icon: panneau,
      backgroundTitre: "fondG",
      description:
        "Opter pour l'énergie verte avec Switch Energy vous permet de participer à un processus éco-responsable axé sur le développement durable, la transition énergétique et la production renouvelable européenne. Les certificats authentifiant la provenance de votre énergie seront disponibles sur votre compte client.",
      image: eolienne,
      ltImg: "Illustration d'éolienne",
    },
    {
      inversion: "0",
      titre: "Souscription rapide et facile",
      icon: conv,
      backgroundTitre: "fondG",
      description:
        "Nous avons conçu une expérience de souscription en ligne simple et rapide pour nos clients. Nous savons que le temps est précieux, c'est pourquoi nous avons simplifié chaque étape du processus d'abonnement. Vous pouvez souscrire en quelques minutes seulement, sans avoir besoin de remplir de longs formulaires ou de fournir des documents compliqués. Nous sommes là pour vous guider à chaque étape du processus et répondre à toutes vos questions. Avec Switch Energy, l'abonnement à l'électricité est simple et rapide.",
      image: barrage,
      altImg: "Illustration d'un barrage",
    },
  ];

  return (
    <div
      id="containerDescription"
      className="hoverflow-hidden d-flex flex-column align-items-center mt-5"
    >
      <Title />
      <div className="container">
        {containersData.map((container, index) => (
          <ContainerDesc
            key={index}
            inversion={container.inversion}
            titre={container.titre}
            icon={container.icon}
            backgroundTitre={container.backgroundTitre}
            description={container.description}
            image={container.image}
            ltImg={container.ltImg}
            altImg={container.altImg}
            class={container.class}
          />
        ))}
      </div>
      <div className="button-and-logo mb-4">
        <div className="mx-auto">
          <a href="tel:0189471956">
            <button className="button_page d-inline-block fw-bold border border-3 rounded-3">
              Souscrire au 01 89 47 19 56
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}
export default Home;
