import React from "react";
import "./titre.css";
import paysage from "../../Images/paysage.png";
import ampoule from "../../Images/ampoule.png";

function Titre() {
  return (
    <div className="my-5 bg-white pt-5">
      <div className="mt-auto mx-5 px-5" >
        <div className="row row w-100 p-4 mx-auto marge custom-bg">
          <div className="col-md-6 mx-auto">
            <section className="mb-4">
              <h2 className="titre">Énergie nouvelle, démarrage simple</h2>
            </section>
          </div>
          <div className="col-md-6 mx-auto">
            <section className="mb-4">
              <p className="fs-4">
                Que vous recherchiez un fournisseur d'énergie pour votre
                résidence actuelle ou pour votre future habitation suite à un
                déménagement, ChezSwitch est à vos côtés. En tant que
                fournisseur électrique, nous mettons à disposition des offres
                claires et adaptées à vos exigences pour vous accompagner au
                quotidien.
              </p>
              <div className="boutonD d-flex justify-content-center align-items-center">
                <a href="tel:0189471956">
                  <button className="button-contacte me-5 border border-2 rounded-3 text-nowarp d-inline-block">
                    Contactez-nous au 01 89 47 19 56
                  </button>
                </a>
                <a href="https://www.chezswitch.fr/offre-energie/#/" target="_blank" rel="noreferrer">
                  <button className="button-souscrire me-5 border border-2 text-dark text-nowarp rounded-3 d-inline-block">
                    <img src={ampoule} className="iconeAmp"  alt="Icône d'ampoule"/>
                    Souscrire en ligne
                  </button>
                </a>
              </div>
            </section>
          </div>
        </div>
        <div className="row row w-100 p-4 mx-auto  custom-bg">
          <section className="text-center">
            <img src={paysage} className="img-fluid" alt="photographie de panneaux solaire"/>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Titre;
