import React from "react";
import "./navbar.css";
import logo from "../../Images/Logo_switch.png";
import logoSwitch from "../../Images/logoSwitch.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <header className="Navbar bg-white vw-100 top-0 position-fixed">
      <nav>
        <div className="d-flex align_items-center justify-content-between">
          <div>
            <Link to="/">
              <img src={logo} className="logo d-lg-block" alt="logo switch électricité"/>
            </Link>
          </div>
          <div className="d-flex align-items-center">
            <a href="tel:0189471956">
              <button className="button d-inline-block border border-3 rounded-3 fw-bold text-nowrap">Souscrire au 01 89 47 19 56</button>
            </a>
            <div>
              <a href="https://www.chezswitch.fr/#/" target="_blank" rel="noreferrer">
                <img src={logoSwitch} className="logo-switch" alt="Logo cez switch"/>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;