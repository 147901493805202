import React from "react";
import "./container_desc.css";
import ImgDesc from "../ImgDesc/Img-desc";
import TextDesc from "../TextDesc/Text_desc";

function Container_desc(props) {
  return (
    <div className={`row w-100 p-4 mx-auto marge bg-white mb-5 overflow-hidden`}>
      <TextDesc
        titre={props.titre}
        icon={props.icon}
        backgroundTitre={props.backgroundTitre}
        description={props.description}
      />
      <ImgDesc image={props.image} inversion={props.inversion} altImg={props.altImg} class={props.class} />
    </div>
  );
}

export default Container_desc;
