import React, { useEffect } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import TitlePage from "../../Components/TitlePage/TitlePage";
import ArticlePolitiqueConfidentialite from "../../Components/ArticlePolitiqueConfidentialite/ArticlePolitiqueConfidentialite";
import Footer from "../../Components/Footer/Footer";



function PolitiqueConfidentialite() {
  useEffect(() => {
    window.scrollTo(0, 0); // Fait défiler la fenêtre en haut de la page
  }, []);

  return (
    <main className="d-flex flex-column align-items-center mb-3">
      <Navbar/>
      <TitlePage title="POLITIQUE DE CONFIDENTIALITÉ" />
      <ArticlePolitiqueConfidentialite />
      <Footer/>
    </main>
  );
}

export default PolitiqueConfidentialite;
