import React from "react";
import "./titlePage.css";

function TitlePage({ title }) {
  return (
    <div className="container-fluid p-0">
      <div className="title-box bg-purple text-purpule fw-bold">
        <h1 className="mb-4">{title}</h1>
      </div>
    </div>
  );
}

export default TitlePage;